/* styles.css */
.next-arrow {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

.prev-arrow {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}
